import React from "react";
import { Link } from "gatsby";
import { StaticImage } from "gatsby-plugin-image";
import Layout from "../components/layout";
import { ArrowInclined } from "../atoms/Icons";

const AboutPage = () => {
  return (
    <Layout>
      {/* Mobile */}
      <h1 className="mobile-header lg:desktop-header">About us</h1>
      <div className="lg:hidden">
        <p className="mobile-body-italic py-4 lg:desktop-body-italic">
          {`This is the story of two very creative souls
        who met, bloomed, combusted, re-bloomed
        and together became artists.
         `}
        </p>

        <div className="max-w-screen overflow-x-scroll overscroll-x-contain lg:overscroll-x-hidden  pl-1 flex gap-4 items-center">
          <StaticImage
            height={273}
            src="../images/about/1.jpg"
            alt="Bob & Dana with birds masks"
            placeholder="tracedSVG"
            className="flex-shrink-0"
          />
          <StaticImage
            height={273}
            src="../images/about/2.jpg"
            alt="Bob & Dana playing music"
            placeholder="tracedSVG"
            className="flex-shrink-0"
          />

          <StaticImage
            height={275}
            src="../images/about/3.jpg"
            alt="Bob & Dana medusas"
            placeholder="tracedSVG"
            className="flex-shrink-0"
          />

          <StaticImage
            height={275}
            src="../images/about/4.jpg"
            alt="Bob & Dana in the pool"
            placeholder="tracedSVG"
            className="flex-shrink-0"
          />
          <StaticImage
            height={275}
            src="../images/about/new.jpeg"
            alt="Bob & Dana in the pool"
            placeholder="tracedSVG"
            className="flex-shrink-0"
          />
        </div>

        <p className="pt-4 mobile-body lg:desktop-body">
          Barefoot Artes was born in the front yard of our rental house, on
          Calle Cuba, in San Pancho, Nayarit. We were building a new house and
          prematurely built a giant 4’ x 8’ work- table that could not fit thru
          the front door of our rental. A fellow flowering artist, Cori Jacobs
          (see
          <a
            href="https://www.corijacobs.com/"
            target="_blank"
            rel="nonrefer"
            className="font-medium italic"
          >
            {" "}
            Corijacobsgallery.com
          </a>
          ) used to stop by to visit, as we worked at the table, in the front
          garden. It quickly grew into twice-weekly sessions of clothing
          production, painting, good coffee and girl chat. Bob was a little
          unnerved by the girl time, but as things got more serious and the
          girls were progressing, he joined in the mayhem. The rest is a history
          of a very well-loved Art table, life in a house we designed from beer
          boxes, in our adopted country of Mexico and becoming best friends.
        </p>
        <br />
        <p className="mobile-body lg:desktop-body">
          They have had several Art Happenings and can be found in Private
          Collections of Ill Repute!
        </p>
        <br />
        <p className="mobile-body lg:desktop-body">
          The Maxey’s reside in the Mountains of Idaho and in San Pancho,
          Nayarit. They love to fly fish, and splash about in the waves.
        </p>
      </div>

      {/* Desktop */}
      <div className="hidden lg:flex  gap-4">
        <div className="w-1/2">
          <p className="mobile-body-italic lg:desktop-body-italic">
            {`This is the story of two very creative souls
        who met, bloomed, combusted, re-bloomed
        and together became artists.
         `}
          </p>

          <div className="desktop-body-justify">
            <p className="pt-2 ">
              Barefoot Artes was born in the front yard of our rental house, on
              Calle Cuba, in San Pancho, Nayarit. We were building a new house
              and prematurely built a giant 4’ x 8’ work- table that could not
              fit thru the front door of our rental. A fellow flowering artist,
              Cori Jacobs (see{" "}
              <a
                href="https://www.corijacobs.com/"
                target="_blank"
                rel="nonrefer"
                className="font-medium italic"
              >
                {" "}
                Corijacobsgallery.com
              </a>
              ) used to stop by to visit, as we worked at the table, in the
              front garden. It quickly grew into twice-weekly sessions of
              clothing production, painting, good coffee and girl chat. Bob was
              a little unnerved by the girl time, but as things got more serious
              and the girls were progressing, he joined in the mayhem. The rest
              is a history of a very well-loved Art table, life in a house we
              designed from beer boxes, in our adopted country of Mexico and
              becoming best friends.
            </p>
            <br />
            <p className="">
              We have had several Art Happenings and can be found in Private
              Collections of Ill Repute!
            </p>
            <br />
            <p className="">
              The Maxey’s reside in the Mountains of Idaho and in San Pancho,
              Nayarit. They love to fly fish, and splash about in the waves.
            </p>
          </div>
        </div>
        <div className="w-3/4 xl:pl-8  max-w-screen overflow-x-scroll overscroll-x-contain lg:overscroll-x-hidden  pl-1 flex gap-4 lg:gap-8 items-end flex-col">
          <StaticImage
            width={816}
            src="../images/about/2.jpg"
            alt="Bob & Dana playing music"
            placeholder="tracedSVG"
            className="flex-shrink-0"
          />
          <div className="flex gap-8">
            <div className="flex flex-col gap-8">
              <StaticImage
                width={400}
                src="../images/about/1.jpg"
                alt="Bob & Dana with birds masks"
                placeholder="tracedSVG"
                className="flex-shrink-0"
              />
              <StaticImage
                width={400}
                src="../images/about/new.jpeg"
                alt="Bob & Dana with birds masks"
                placeholder="tracedSVG"
                className="flex-shrink-0"
              />
            </div>

            <div className="flex flex-col gap-4">
              <StaticImage
                width={309}
                src="../images/about/5.png"
                alt="Dana"
                placeholder="tracedSVG"
                className="flex-shrink-0"
              />
              <Link className="flex items-center font-bold" to="/meet-dana">
                <ArrowInclined /> Meet Dana
              </Link>
              <StaticImage
                width={309}
                src="../images/about/6.png"
                alt="Bob"
                placeholder="tracedSVG"
                className="flex-shrink-0"
              />
              <Link
                className=" pb-4 flex items-center font-bold"
                to="/meet-bob"
              >
                <ArrowInclined />
                Meet Bob
              </Link>
            </div>
          </div>
        </div>
      </div>
    </Layout>
  );
};

export default AboutPage;
